import { TradingDocument } from "api/trading-documents/models";
import { Table } from "components/miloDesignSystem/molecules/table/Table";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { NormalizedTradingDocumentItem } from "../salesInvoiceItems/SalesInvoiceItems";
import { useItemsColumns } from "./useItemsColumns";

interface Props {
  tradingDocument: TradingDocument;
}

export const TradingDocumentItems = ({ tradingDocument }: Props) => {
  const items = tradingDocument.items.flatMap(item =>
    item.tradingDocumentItems.map(tradingDocumentItem => ({
      ...tradingDocumentItem,
      order: item.order,
    })),
  );
  const columns = useItemsColumns();
  const tableProps = useTableFeatureConnector({
    rows: items,
  });

  return (
    <Table<NormalizedTradingDocumentItem>
      columns={columns}
      error={null}
      isLoading={false}
      uiSchema={{
        header: {
          backgroundColor: "neutralWhite100",
        },
        cell: {
          height: "30",
        },
      }}
      {...tableProps}
    />
  );
};
