import { TradingDocument } from "api/trading-documents/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { useRemoveTradingDocumentItem } from "../hooks/useRemoveTradingDocumentItem";
import { NormalizedTradingDocumentItem } from "./SalesInvoiceItems";
import { EditDiscountSection } from "../EditDiscountSection";
import { EditAmountWithTaxSection } from "../EditAmountWithTaxSection";
import { EditVatRateSection } from "../editVatRateSection/EditVatRateSection";

export const useItemsColumns = (tradingDocument: TradingDocument) => {
  const removeTradingDocumentItemMutation = useRemoveTradingDocumentItem(tradingDocument.id);

  return useCreateTableColumns<NormalizedTradingDocumentItem>(({ columnHelper }) => {
    return [
      columnHelper.text(row => `${row.displayPosition}.`, {
        header: "#",
        size: 25,
      }),
      columnHelper.accessor(row => row, {
        header: "nazwa towaru",
        size: 110,
        cell: info => {
          const item = info.getValue();
          return (
            <div className="d-flex align-items-center justify-content-between gap-2 w-100">
              <div className="cut-text">
                <Typography fontSize="12" fontWeight="700" noWrap>
                  {item.name}
                </Typography>
              </div>
              {tradingDocument.status !== "CONFIRMED" && (
                <IconButton
                  icon={MdiDelete}
                  isLoading={removeTradingDocumentItemMutation.isLoading}
                  onClick={() =>
                    removeTradingDocumentItemMutation.mutate({
                      id: item.id,
                      itemId: item.id,
                      name: item.name,
                      tradingDocumentId: tradingDocument.id,
                    })
                  }
                  variant="transparent"
                />
              )}
            </div>
          );
        },
      }),
      columnHelper.text(row => row.order?.signature, {
        header: "zamówienie",
        size: 90,
      }),
      columnHelper.accessor(row => row, {
        id: "discount",
        header: () => (
          <Typography
            className="text-center w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            rabat
          </Typography>
        ),
        size: 51,
        cell: info => {
          const item = info.getValue();
          return (
            <EditDiscountSection
              tradingDocumentId={tradingDocument.id}
              tradingDocumentItem={item}
            />
          );
        },
      }),
      columnHelper.amount(row => row.discountAmount, {
        header: "w. rabatu",
        size: 51,
        textAlign: "right",
        amountDisplayProps: {
          decimal: {
            fontSize: "10",
            fontWeight: "700",
          },
          integer: {
            fontSize: "12",
            fontWeight: "700",
          },
        },
      }),
      columnHelper.number(row => row.quantity, {
        header: "liczba",
        size: 36,
        textAlign: "right",
      }),
      columnHelper.text(row => row.unit, {
        header: "jedn.",
        size: 35,
      }),
      columnHelper.accessor(row => row, {
        id: "amountWithTax",
        header: () => (
          <Typography
            className="text-center w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            c. brutto
          </Typography>
        ),
        size: 65,
        cell: info => {
          const item = info.getValue();
          return (
            <EditAmountWithTaxSection
              tradingDocumentId={tradingDocument.id}
              tradingDocumentItem={item}
            />
          );
        },
      }),
      columnHelper.amount(row => row.amountWithTaxAfterDiscount, {
        header: "c. brutto po r.",
        size: 75,
        amountDisplayProps: {
          decimal: {
            fontSize: "10",
            fontWeight: "700",
          },
          integer: {
            fontSize: "12",
            fontWeight: "700",
          },
        },
      }),
      columnHelper.accessor(row => row, {
        id: "vat",
        header: () => (
          <Typography
            className="text-center w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            VAT
          </Typography>
        ),
        size: 55,
        cell: info => {
          const item = info.getValue();
          return (
            <EditVatRateSection tradingDocumentId={tradingDocument.id} tradingDocumentItem={item} />
          );
        },
      }),
      columnHelper.amount(row => row.totalAmountWithTaxAfterDiscount, {
        header: "w. brutto",
        size: 65,
        textAlign: "right",
        amountDisplayProps: {
          decimal: {
            fontSize: "10",
            fontWeight: "700",
          },
          integer: {
            fontSize: "12",
            fontWeight: "700",
          },
        },
      }),
    ];
  });
};
