import { TradingDocument, TradingDocumentItem } from "api/trading-documents/models";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { useItemsColumns } from "./useItemsColumns";

interface Props {
  tradingDocument: TradingDocument;
}

export type NormalizedTradingDocumentItem = TradingDocumentItem & {
  order: {
    created: string;
    id: number | string;
    signature: string;
  } | null;
};

export const SalesInvoiceItems = ({ tradingDocument }: Props) => {
  const items = tradingDocument.items.flatMap(item =>
    item.tradingDocumentItems.map(tradingDocumentItem => ({
      ...tradingDocumentItem,
      order: item.order,
    })),
  );
  const columns = useItemsColumns(tradingDocument);
  const tableProps = useTableFeatureConnector({
    rows: items,
  });

  return (
    <Table<NormalizedTradingDocumentItem>
      columns={columns}
      error={null}
      isLoading={false}
      uiSchema={{
        header: {
          backgroundColor: "neutralWhite100",
        },
        cell: {
          height: "36",
        },
      }}
      {...tableProps}
    />
  );
};
